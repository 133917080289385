<template>
  <a-card>
    <div slot="title">基本信息</div>
    <a-form-model v-bind="$formItemLayout4DetailSm">
      <a-form-model-item label="名称：">
        <span>{{ goodsSource.goodsName }}</span>
      </a-form-model-item>
      <a-form-model-item label="封面：">
        <a-avatar :size="70" shape="square" :src="goodsSource.coverUrl"></a-avatar>
      </a-form-model-item>
      <a-form-model-item label="介绍：">
        <span>{{ goodsSource.introduction }}</span>
      </a-form-model-item>
      <a-form-model-item label="服务特色：">
        <span>{{ goodsSource.characteristic }}</span>
      </a-form-model-item>
      <a-form-model-item label="购买须知：">
        <span>{{ goodsSource.purchaseInstructions }}</span>
      </a-form-model-item>
      <a-form-model-item label="视频介绍：">
        <a
          :href="goodsSource.videoUrl"
          flex="1"
          style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;"
          target="_blank"
          rel="noopener noreferrer"
          >{{ goodsSource.videoUrl }}</a
        >
      </a-form-model-item>
    </a-form-model>
    <a-row>
      <a-col :xs="{ span: 22, offset: 1 }">
        <a-table
          bordered
          rowKey="id"
          :data-source="goodsSource.goodsServiceCombinationSpecificationsSet"
          :pagination="false"
          style="margin-bottom:20px"
        >
          <a-table-column key="status" title="序号" align="center" width="80px">
            <template slot-scope="text, record, index">
              <span>{{ index + 1 }}</span>
            </template>
          </a-table-column>
          <a-table-column key="goodsName" title="商品名称" align="center" data-index="goodsName" width="120px" />
          <a-table-column key="description" title="规格" align="center" data-index="description" width="120px" />
          <a-table-column key="value" title="值" align="center" data-index="value" width="120px" />
          <a-table-column key="sellPrice" title="价格" align="center" data-index="sellPrice" width="120px" />
          <a-table-column
            key="isValueAddedService"
            title="是否为增值服务"
            align="center"
            data-index="isValueAddedService"
            width="80px"
          >
            <template slot-scope="isValueAddedService">
              <a-badge
                :status="isValueAddedService ? 'success' : 'error'"
                :text="isValueAddedService ? '是' : '否'"
              ></a-badge>
            </template>
          </a-table-column>
        </a-table>
      </a-col>
    </a-row>
    <a-form-model v-bind="$formItemLayout4DetailSm">
      <a-form-model-item label="原始售价：">
        <span>￥{{ goodsSource.originPrice }}</span>
      </a-form-model-item>
      <a-form-model-item label="折扣：">
        <span>{{ goodsSource.discount }}%</span>
      </a-form-model-item>
      <a-form-model-item label="实际售价：">
        <span>￥{{ goodsSource.sellPrice }}</span>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
import * as goodsserviceApi from '@/api/goodsservice'
export default {
  name: 'GoodsManagementServiceCombinationDetail',

  data() {
    return {
      goodsSource: {
        characteristic: '',
        coverUrl: '',
        discount: '',
        goodsServiceCombinationSpecificationsSet: [
          {
            description: '',
            goodsName: '',
            id: '',
            isValueAddedService: false,
            sellPrice: '',
            value: ''
          }
        ],
        id: 0,
        introduction: '',
        goodsName: '',
        originPrice: 0,
        purchaseInstructions: '',
        sellPrice: 0,
        videoUrl: ''
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { id }
        }
      } = this
      goodsserviceApi.combinationDetail(id).then(res => {
        this.goodsSource = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
